import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import Test from '../Login/Test';
import { Search } from '../Search/Search';
import './Navbar.css';
import * as Components from "./Components";

function Navbar() {
  const [signIn, toggle] = React.useState(true);
  const [auth, setAuth] = useState(localStorage.getItem('user') ? true : false);
  const { category } = useParams();
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  const navigate = useNavigate();
  const [user, setUser] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [signupUser, setSignupUser] = useState({ firstName: '', email: '', password: '' });


  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showLoginSignup && !e.target.closest('.container')) {
        setShowLoginSignup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showLoginSignup]);


  const validateLoginForm = (user, isLogin = false) => {
    const errors = {};
    if (!user.email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = 'Email address is invalid.';
    }
    // if (!isLogin && !user.firstName) {
    //   errors.firstName = 'Name is required.';
    // }
    if (!user.password) {
      errors.password = 'Password is required.';
    } else if (user.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long.';
    }
    return errors;
  };

  const validateSignupForm = (user, isLogin = false) => {
    const errors = {};
    if (!user.email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = 'Email address is invalid.';
    }
    if (!isLogin && !user.firstName) {
      errors.firstName = 'Name is required.';
    }
    if (!user.password) {
      errors.password = 'Password is required.';
    } else if (user.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long.';
    }
    return errors;
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = validateLoginForm(user);

      if (Object.keys(validationErrors).length === 0) {
        const res = await axios.post('https://devapi.scoreboardmaster.com/api/users/login', user);
      localStorage.setItem('user', JSON.stringify(res.data));
      localStorage.setItem('Authorization', res.data.data.accessToken);
      setAuth(true);
      setShowLoginSignup(false)
      setUser({email: '', password: '' })
      setErrors("")
      navigate('/');
      } else {
        setErrors(validationErrors);
      }
    } catch (error) { 
      setErrors({backendError:error.response.data.data.message})
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      console.log(signupUser,"signup user data")
      const validationErrors = validateSignupForm(signupUser);
      if (Object.keys(validationErrors).length === 0) {
        signupUser.authType = "app"
      signupUser.deviceType = "web"
      const res = await axios.post('https://devapi.scoreboardmaster.com/api/users', signupUser);
      localStorage.setItem('user', JSON.stringify(res.data));
      localStorage.setItem('Authorization', res.data.data.accessToken);
      setAuth(true);
      setShowLoginSignup(false)
      setSignupUser({ firstName: '', email: '', password: '' })
      setErrors("")
      navigate('/profile');
      } else {
        setErrors(validationErrors);
      }
    } catch (error) {
      setErrors({backendError:error.response.data.data.message});
    }
  };

  const logOutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('Authorization');
    setAuth(false);
    navigate('/');
  };

  return (
    <>
      <div className="nav">
        <div className="nav-left">
          <div>
            <Link to="/">
              <img
                className="disney-img"
                src="https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/test/appData/1711785637950_1711736659718gwz20ix9_removebg.png"
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="nav-right">
          {/* <Search /> */}
          {auth ? (
            <div className="dropdown">
              <div className="link" to="/settings">
                Settings
              </div>
              <ul>
                <li>
                  <Link to="/profile">My Account</Link>
                </li>
                <li>
                  <Link to="/create-tournament">Create Tournament</Link>
                </li>
                <li>
                  <Link to="/my-tournament">My Tournament</Link>
                </li>                 
                <li onClick={logOutUser}>Log Out</li>
              </ul>
            </div>
          ) : (
            <div>
              <div onClick={() => setShowLoginSignup(true)}>LOGIN/SIGNUP</div>
              {/* <div onClick={() => setShowSignup(true)}>SIGNUP</div> */}
            </div>
          )}
          <button onClick={() => navigate('/contact-us')} className="contact-button">
            Contact Us
          </button>
        </div>
      </div>
      <div>
      {showLoginSignup && (
        <div className="container">
          <Components.closeButton onClick={() => setShowLoginSignup(false)}>X</Components.closeButton>
          <Components.Container>
            <Components.SignUpContainer signingIn={signIn}>
              <Components.Form>
                <Components.Title>Create Account</Components.Title>
                <div className="input-container">
                  <input
                    type="email"
                    placeholder="Name"
                    value={signupUser.firstName}
                    onChange={(e) => setSignupUser({ ...signupUser, firstName: e.target.value })}
                  />
                </div>
                {errors.firstName && <Components.ErrorMessage>{errors.firstName}</Components.ErrorMessage>}
                <div className="input-container">
                  <input
                    type="email"
                    placeholder="Email"
                    value={signupUser.email}
                    onChange={(e) => setSignupUser({ ...signupUser, email: e.target.value })}
                  />
                </div>
                {errors.email && <Components.ErrorMessage>{errors.email}</Components.ErrorMessage>}
                <div className="input-container">
                  <input
                    type="password"
                    placeholder="Password"
                    value={signupUser.password}
                    onChange={(e) => setSignupUser({ ...signupUser, password: e.target.value })}
                  />
                </div>
                {errors.password && <Components.ErrorMessage>{errors.password}</Components.ErrorMessage>}
                {errors.backendError && <Components.ErrorMessage>{errors.backendError}</Components.ErrorMessage>}
                <Components.Button onClick={handleSignup}>Sign Up</Components.Button>
              </Components.Form>
            </Components.SignUpContainer>
            <Components.SignInContainer signingIn={signIn}>
              <Components.Form>
                <Components.Title>Sign in</Components.Title>
                <div className="input-container">
                  <input
                    type="email"
                    placeholder="Email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                  {errors.email && <Components.ErrorMessage>{errors.email}</Components.ErrorMessage>}
                </div>
                <div className="input-container">
                  <input
                    type="password"
                    placeholder="Password"
                    value={user.password}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                  />
                  {errors.password && <Components.ErrorMessage>{errors.password}</Components.ErrorMessage>}
                </div>
                {errors.backendError && <Components.ErrorMessage>{errors.backendError}</Components.ErrorMessage>}
                <Components.Anchor href="#">Forgot your password?</Components.Anchor>
                <Components.Button onClick={handleLogin}>Sign In</Components.Button>
              </Components.Form>
            </Components.SignInContainer>
            <Components.OverlayContainer signingIn={signIn}>
              <Components.Overlay signingIn={signIn}>
                <Components.LeftOverlayPanel signingIn={signIn}>
                  <Components.Title>Welcome Back!</Components.Title>
                  <Components.Paragraph>
                    To keep connected with us please login with your personal info
                  </Components.Paragraph>
                  <Components.GhostButton onClick={() => { toggle(true); setErrors(""); }}>
                    Sign In
                  </Components.GhostButton>
                </Components.LeftOverlayPanel>
                <Components.RightOverlayPanel signingIn={signIn}>
                  <Components.Title>Hello, Friend!</Components.Title>
                  <Components.Paragraph>
                    Enter your personal details and start journey with us
                  </Components.Paragraph>
                  <Components.GhostButton onClick={() => { toggle(false); setErrors(""); }}>
                    Sign Up
                  </Components.GhostButton>
                </Components.RightOverlayPanel>
              </Components.Overlay>
            </Components.OverlayContainer>
          </Components.Container>
        </div>
      )}
    </div>
    </>
  );
}

export default Navbar;
