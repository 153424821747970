import React, { useState, useEffect } from "react";
import "./matchDetail.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignal,
  faList,
  faUsers,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { connectToBroker, subscribeToTopic, disconnectFromBroker, publishMessage } from '../../services/mqttService';


import axios from 'axios';

function MatchDetailsPage() {
  const [activeTab, setActiveTab] = useState("live-score");
  const [liveScoreData, setLiveScoreData] = useState(null);
  const [scorecardData, setScorecardData] = useState({});
  const [scorecardData1, setScorecardData1] = useState({});
  const [squadsInfoData, setSquadsInfoData] = useState(null);
  const [matchSummaryData, setMatchSummaryData] = useState(null);
  const [matchstartDate, setMatchStartDate] = useState(null);
  const [matchStatus, setMatchStatus] = useState("pending");
  const [inningNo, setInningNo] = useState(1)
  const [overCount, setOverCount] = useState(0)
  const [maxOver, setMaxOver] = useState(1)
  const [wicketCount, setWicketCount] = useState(1)
  const [batting, setBatting] = useState("")
  const [team1, setTeamA] = useState("")
  const [team2, setTeamB] = useState("")
  const [remainingBalls, setRemainingBalls] = useState(0)
  const [remainingRuns, setRemainingRuns] = useState(0)
  const [target, setTarget] = useState(0)



  const [mqttClient, setMqttClient] = useState(null);
  const { id } = useParams();


  useEffect(() => {
    fetchLiveScoreData();
    fetchScorecardData();
    fetchMatchSummaryData();
  }, []);

  useEffect(() => {
    // Check if the MQTT client is already connected
    if (!mqttClient) {
      // If not connected, connect to the broker
      const client = connectToBroker('mqtt://13.201.146.201:15675/ws');
      setMqttClient(client);
    }

    // Subscribe to a topic to receive updates
    subscribeToTopic('scoreboardMaster/dev/cricket/match/updates', (topic, message) => {
      // Handle incoming messages
      console.log(`Received message on topic ${topic}: ${message}`);
      message = JSON.parse(message)

      setScorecardData(scorecardData => {
        const updatedBatters = scorecardData.battersData.map(batter => {
          if (batter.playerId === message.batter1.id) {
            return {
              ...batter,
              runs: message.batter1.run,
              name: message.batter1.name,
              balls: message.batter1.ball,
              fours: message.batter1.four,
              sixes: message.batter1.six,
              strikeRate : message.batter1.strikeRate
            };
          }else if (batter.playerId === message.batter2.id){
            return {
              ...batter,
              runs: message.batter2.run,
              name: message.batter2.name,
              balls: message.batter2.ball,
              fours: message.batter2.four,
              sixes: message.batter2.six,
              strikeRate : message.batter2.strikeRate
            };
          }
          return batter;
        });

        const updatedBowlers = scorecardData.bowlersData.map(bowler => {
          if (bowler.playerId === message.bowler.id) {
            return {
              ...bowler,
              overs: message.bowler.over,
              name: message.bowler.name,
              wickets: message.bowler.wicket,
              economy: message.bowler.economy,
              runs: message.bowler.run,
            };
          }
          return bowler;
        });

        return {
          ...scorecardData,
          battersData: updatedBatters,
          bowlersData: updatedBowlers,
          totalOvers: message.totalOvers,
          totalScore: message.totalRuns,
          totalWickets: message.wicketCount,
          extras: message.extras.total
        };
      });
      
      // Update the scoreboard state based on the received message
      // updateScoreboardState(JSON.parse(message));
    });
  }, []);


  const fetchLiveScoreData = async () => {
    try {
      // const response = await axios.get('your-backend-url/live-score');

      const dummyLiveScoreData = {
            someValue: "Live score: Team A - 100/2 in 10 overs",
          };
      setLiveScoreData(dummyLiveScoreData);
    } catch (error) {
      console.error('Error fetching live score data:', error);
    }
  };
 const convertEpochToDate = (epochInSeconds) => {
    const date = new Date(epochInSeconds * 1000);
    return date.toLocaleString();
  };

 

  const fetchScorecardData = async () => {
    try {
      const queryParam = `gameId=${id}`;
      const response = await axios.get(`https://devapi.scoreboardmaster.com/api/match/scorecard?${queryParam}`);
        let ApiData = response.data.data.games[0]
        let scorecardDataTeam1 = {}
        let scorecardDataTeam2 = {}

        let teamAbattersData =  []
        let teamBbattersData =  []
        let teamAbowlersData =  []
        let teamBbowlersData =   []
        const formattedDate = convertEpochToDate(ApiData.insertDate);
        setMatchStartDate(formattedDate)
        setMatchStatus(ApiData.gameStatus)
        setInningNo(ApiData.inningNo)
        setBatting(ApiData.toss)
        setTeamA(ApiData.teamA)
        setTeamB(ApiData.teamB)
        setMaxOver(parseInt(ApiData.totalOvers))

        const squadsInfoData = {
          teamA: [],
          teamB: [],
        };

        if(ApiData.inningNo === 2){
          setRemainingRuns(ApiData.remainingRuns)
          setRemainingBalls(ApiData.remainingBalls)
        }
        for (let index = 0; index < ApiData.teams.length; index++) {
          const element = ApiData.teams[index];

          // let teamAbattersData = teamAbattersData && teamAbattersData.length > 0? teamAbattersData : []
          // let teamBbattersData = teamBbattersData && teamBbattersData.length > 0? teamBbattersData : []
          // let teamAbowlersData = teamAbowlersData && teamAbowlersData.length > 0? teamAbowlersData : []
          // let teamBbowlersData =  teamBbowlersData && teamBbowlersData.length > 0? teamBbowlersData : []

          if(element.name === ApiData.toss){
              scorecardDataTeam1.teamName = element.name
              scorecardDataTeam1.totalWickets = element.totalWickets ? element.totalWickets : 0
              scorecardDataTeam1.totalOvers = element.totalOvers?element.totalOvers :0
              scorecardDataTeam1.extras = element.extras? element.extras : 0
              scorecardDataTeam1.totalScore = element.totalScore? element.totalScore : 0
              scorecardDataTeam1.didNotBat = []
              scorecardDataTeam1.fallOfWickets = element.fallOfWickets? element.fallOfWickets : [
                      { player: "Player 1", score: 30, overs: "5.2" },
                      { player: "Player 2", score: 75, overs: "9.4" },
                    ]
          
              
              for (let playerIndex = 0; playerIndex < element.players.length; playerIndex++) {
                const playerElement = element.players[playerIndex];
                squadsInfoData.teamA.push({
                  name : playerElement.playerName,
                  role : playerElement.role,
                  image : playerElement.profilePic
                })
                if(playerElement.battingStatus ==="PENDING"){
                  scorecardDataTeam1.didNotBat.push(playerElement.playerName)
                }
                if(playerElement.battingOrder && playerElement.battingOrder > 0 ){
                  let batter = {}
                batter.runs = playerElement.battingStats.runs
                batter.name  = playerElement.playerName
                batter.balls = playerElement.battingStats.balls
                batter.fours = playerElement.battingStats.fours
                batter.sixes = playerElement.battingStats.sixes
                batter.strikeRate = playerElement.battingStats.strikeRate
                batter.isStriker = playerElement.isStriker
                batter.playerId = playerElement.playerId
                batter.battingStatus = playerElement.battingStatus
                teamAbattersData.push(batter)
                }

                if(playerElement.isBowl){
                 let bowler = {}
                bowler.name  = playerElement.playerName
                bowler.overs  = playerElement.bowlingStats.overs
                bowler.runs  = playerElement.bowlingStats.runs
                bowler.wickets  = playerElement.bowlingStats.wickets
                bowler.economy  = playerElement.bowlingStats.economy
                bowler.isBowl = playerElement.isBowl
                bowler.playerId = playerElement.playerId
                teamBbowlersData.push(bowler)
                }
                
              }
              scorecardDataTeam1.battersData = teamAbattersData
              scorecardDataTeam2.bowlersData = teamBbowlersData
           
          }else{
              scorecardDataTeam2.teamName = element.name
              scorecardDataTeam2.totalWickets = element.totalWickets ? element.totalWickets : 0
              scorecardDataTeam2.totalOvers =  element.totalOvers?element.totalOvers :0
              scorecardDataTeam2.extras = element.extras? element.extras : 0
              scorecardDataTeam2.totalScore = element.totalScore? element.totalScore : 0
              scorecardDataTeam2.didNotBat = []
              scorecardDataTeam2.fallOfWickets = element.fallOfWickets? element.fallOfWickets : [
                    { player: "Player 1", score: 30, overs: "5.2" },
                    { player: "Player 2", score: 75, overs: "9.4" },
                  ]
            
            for (let playerIndex = 0; playerIndex < element.players.length; playerIndex++) {
              const playerElement = element.players[playerIndex];

              squadsInfoData.teamB.push({
                name : playerElement.playerName,
                role : playerElement.role,
                image : playerElement.profilePic
              })

              if(playerElement.battingStatus === "PENDING"){
                scorecardDataTeam2.didNotBat.push(playerElement.playerName)
              }


              if(playerElement.battingOrder && playerElement.battingOrder > 0 ){
                let batter = {}
              batter.runs = playerElement.battingStats.runs
              batter.name  = playerElement.playerName
              batter.balls = playerElement.battingStats.balls
              batter.fours = playerElement.battingStats.fours
              batter.sixes = playerElement.battingStats.sixes
              batter.strikeRate = playerElement.battingStats.strikeRate
              batter.playerId = playerElement.playerId
              batter.battingStatus = playerElement.battingStatus
              teamBbattersData.push(batter)
              }
              if(playerElement.isBowl){
              let bowler = {}
              bowler.overs  = playerElement.bowlingStats.overs
              bowler.name  = playerElement.playerName
              bowler.wickets  = playerElement.bowlingStats.wickets
              bowler.economy  = playerElement.bowlingStats.economy
              bowler.playerId = playerElement.playerId
              bowler.runs  = playerElement.bowlingStats.runs
              teamAbowlersData.push(bowler)
              }  
            }
            scorecardDataTeam2.battersData = teamBbattersData
            scorecardDataTeam1.bowlersData = teamAbowlersData
          }

          if(element.isBatting){
            setWicketCount(element.totalWickets)
            setOverCount(element.overCount)
            
          }else{
            setTarget(element.totalScore + 1)

          }
        }
        
        setScorecardData(scorecardDataTeam1);
        setScorecardData1(scorecardDataTeam2);
        setSquadsInfoData(squadsInfoData)
    } catch (error) {
      console.error('Error fetching  scorecard data:', error);
    }

    
  };

  // const fetchSquadsInfoData = async () => {
  //   // Dummy squads info data
  //   const dummySquadsInfoData = {
  //     teamA: [
  //       {
  //         name: "Player 1",
  //         role: "Batter",
  //         image: "https://dummyimage.com/100x100/333/fff",
  //       },
  //       {
  //         name: "Player 2",
  //         role: "Bowler",
  //         image: "https://dummyimage.com/100x100/666/fff",
  //       },
  //       // Add more players for Team A
  //     ],
  //     teamB: [
  //       {
  //         name: "Player 1",
  //         role: "Batter",
  //         image: "https://dummyimage.com/100x100/000/fff",
  //       },
  //       {
  //         name: "Player 2",
  //         role: "Bowler",
  //         image: "https://dummyimage.com/100x100/999/fff",
  //       },
  //       // Add more players for Team B
  //     ],
  //   };

  //   setSquadsInfoData(dummySquadsInfoData);
  // };

  const fetchMatchSummaryData = async () => {
    // Dummy match summary data
    const dummyMatchSummaryData = {
      someValue: "Match summary: Team A won by 50 runs",
    };
    setMatchSummaryData(dummyMatchSummaryData);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const scoringTeam = batting === team1 ? team1 : team2
  const chessingTeam = scoringTeam === team1 ? team2 : team1

  let winningMessage = `${inningNo === 1 ? scoringTeam : chessingTeam} needs ${remainingRuns} ${
    remainingRuns <= 1 ? 'run' : 'runs'
  } in ${remainingBalls} ${remainingBalls <= 1 ? 'ball' : 'balls'} to win`
  if (inningNo === 2) {

    if (wicketCount < 10 && overCount <= maxOver && scorecardData1.totalScore >= target) {
      winningMessage = `${chessingTeam} won by ${10 - wicketCount} wickets`
    }
 
    if ((wicketCount >= 10 || overCount >= maxOver) && scorecardData1.totalScore < target - 1) {
      winningMessage = `${scoringTeam} won by ${target - scorecardData1.totalScore - 1} runs`
    }
    if (wicketCount < 10 && overCount === maxOver && scorecardData1.totalScore === target - 1) {
      winningMessage = 'Match Tied'
    }
  }

  let rrr = (remainingRuns / (remainingBalls / 6)).toFixed(2)
  rrr = isFinite(rrr) ? rrr : 0

  const welcomeContent = (
    <>
      <div></div>
      <div>Welcome to Score board master</div>
      <div></div>
    </>
  )
  const matchNotStarted = (
    <>
      <div></div>
      <div>Match not started yet</div>
      <div></div>
    </>
  )
  const firstInningCompletedContent = (
    <>
      {overCount >= maxOver && <div>1st inning completed</div>}
      {wicketCount >= 10 && <div>All Out</div>}
      <div>Please click "End Inning" button</div>
    </>
  )
  const remainingRunsContent = (
    <>
      <div>Target: {target}</div>
      <div>{winningMessage}</div>
      <div>RRR: {isNaN(rrr) ? 0 : rrr}</div>
    </>
  )


  return (
    <div className="match-details-container">
      {/* <div className="match-banner" style={{backgroundImage: `url('https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/tycoonland/1716540377029_vicky-adams-gywHscPZwMM-unsplash.jpg')`}}>
  <div className="team-info">
    <img
      src="https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/test/appData/1711785637950_1711736659718gwz20ix9_removebg.png"
      alt="Team A Logo"
      className="team-logo"
    />
    <p className="team-name">Team A</p>
  </div>
  <div className="vs-text">vs</div>
  <div className="team-info">
    <img
      src="https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/test/appData/1711785637950_1711736659718gwz20ix9_removebg.png"
      alt="Team B Logo"
      className="team-logo"
    />
    <p className="team-name">Team B</p>
  </div>
  <p className="match-info">Date: March 31, 2024 | Time: 7:00 PM</p>
</div> */}
<div class="match-banner" style={{backgroundImage: `url('https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/tycoonland/1716540377029_vicky-adams-gywHscPZwMM-unsplash.jpg')`}}>
  <div class="team-info left">
    <h1>
      <img src="https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/test/appData/1711785637950_1711736659718gwz20ix9_removebg.png" alt="Team 1 Logo" class="team-logo" />
      <span>{scorecardData.teamName}</span>
    </h1>
  </div>
  <div class="vs-text">vs</div>
  <div class="team-info right">
    <h1>
      <img src="https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/test/appData/1711785637950_1711736659718gwz20ix9_removebg.png" alt="Team 2 Logo" class="team-logo" />
      <span>{scorecardData1.teamName}</span>
    </h1>
  </div>
  <div className="match-date">
    <p>Date: {matchstartDate}</p>
  </div>
</div>
      <div id='badge' className='badge badge-flex'>
        {matchStatus === "pending"? matchNotStarted :inningNo === 2 ? remainingRunsContent : overCount >= maxOver || wicketCount >= 10 ? firstInningCompletedContent : welcomeContent}
      </div>

      <div className="tabs">
        <button
          className={activeTab === "live-score" ? "active" : ""}
          onClick={() => handleTabChange("live-score")}
        >
          <FontAwesomeIcon icon={faSignal} /> Live Score
        </button>
        <button
          className={activeTab === "scorecard" ? "active" : ""}
          onClick={() => handleTabChange("scorecard")}
        >
          <FontAwesomeIcon icon={faList} /> Scorecard
        </button>
        <button
          className={activeTab === "squads-info" ? "active" : ""}
          onClick={() => handleTabChange("squads-info")}
        >
          <FontAwesomeIcon icon={faUsers} /> Squads Info
        </button>
        <button
          className={activeTab === "match-summary" ? "active" : ""}
          onClick={() => handleTabChange("match-summary")}
        >
          <FontAwesomeIcon icon={faCommentAlt} /> Match Summary
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "live-score" && liveScoreData && (
          <div className="live-score-tab">
            {matchStatus === "pending" && (
              <p>Match not started yet</p>
            )}

            {matchStatus != "pending" && (
              <p>{liveScoreData.someValue}</p>
            )}
           
          </div>
        )}

        {activeTab === "scorecard" && scorecardData && (
          <div className="scorecard-tab">
            {/* <div className="accordion"> */}

            {matchStatus === "pending" && (
              <p>Match not started yet</p>
            )}
            {scorecardData != {} && scorecardData.battersData && scorecardData.battersData.length > 0 && (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <b>{scorecardData.teamName} Innings: {scorecardData.totalScore}-{scorecardData.totalWickets} ({scorecardData.totalOvers} Overs)</b>
              </AccordionSummary>
              <AccordionDetails>
                {/* <div className="team-score"> */}
                <table>
                  <thead>
                    <tr>
                      <th>Batter</th>
                      <th>Batting status</th>
                      <th>R</th>
                      <th>B</th>
                      <th>4s</th>
                      <th>6s</th>
                      <th>SR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scorecardData.battersData.map((batter, index) => (
                      <tr key={index}>
                        <td>{batter.name}</td>
                        <td>{batter.battingStatus === "BATTING" ? "not out": "out"}</td>
                        <td>{batter.runs}</td>
                        <td>{batter.balls}</td>
                        <td>{batter.fours}</td>
                        <td>{batter.sixes}</td>
                        <td>{batter.strikeRate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="additional-info">
                  <p>
                    <b>Extras : </b> {scorecardData.extras}
                  </p>
                  <p>
                    <b>Total : </b> {scorecardData.totalScore}
                  </p>
                  <p>
                    <b>Did not Bat : </b> {scorecardData.didNotBat.join(", ")}
                  </p>
                  <p>
                    <b>Fall of Wickets : </b>{" "}
                    {scorecardData.fallOfWickets
                      .map(
                        (wicket) =>
                          `${wicket.player}-${wicket.score} (${wicket.overs})`
                      )
                      .join(", ")}
                  </p>
                </div>
                <h2>Opposing Team</h2>
                <p>Bowling Details</p>
                <table>
                  <thead>
                    <tr>
                      <th>Bowler</th>
                      <th>Overs</th>
                      <th>Runs</th>
                      <th>Wickets</th>
                      <th>Economy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scorecardData.bowlersData.map((bowler, index) => (
                      <tr key={index}>
                        <td>{bowler.name}</td>
                        <td>{bowler.overs}</td>
                        <td>{bowler.runs}</td>
                        <td>{bowler.wickets}</td>
                        <td>{bowler.economy}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
            )}
            {scorecardData1 != {} && scorecardData1.battersData && scorecardData1.battersData.length > 0 &&(
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <b>{scorecardData1.teamName} Innings: {scorecardData1.totalScore}-{scorecardData1.totalWickets} ({scorecardData1.totalOvers} Overs)</b>
              </AccordionSummary>
              <AccordionDetails>
                <table>
                  <thead>
                    <tr>
                      <th>Batter</th>
                      <th>Batting status</th>
                      <th>R</th>
                      <th>B</th>
                      <th>4s</th>
                      <th>6s</th>
                      <th>SR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scorecardData1.battersData.map((batter, index) => (
                      <tr key={index}>
                        <td>{batter.name}</td>
                        <td>{batter.battingStatus === "BATTING" ? "not out": "out"}</td>
                        <td>{batter.runs}</td>
                        <td>{batter.balls}</td>
                        <td>{batter.fours}</td>
                        <td>{batter.sixes}</td>
                        <td>{batter.strikeRate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="additional-info">
                  <p>
                    <b>Extras : </b> {scorecardData1.extras}
                  </p>
                  <p>
                    <b>Total : </b> {scorecardData1.totalScore}
                  </p>
                  <p>
                    <b>Did not Bat : </b> {scorecardData1.didNotBat.join(", ")}
                  </p>
                  <p>
                    <b>Fall of Wickets : </b>{" "}
                    {scorecardData1.fallOfWickets
                      .map(
                        (wicket) =>
                          `${wicket.player}-${wicket.score} (${wicket.overs})`
                      )
                      .join(", ")}
                  </p>
                </div>
                {/* </div> */}
                {/* <div className="team-score"> */}
                <h2>Opposing Team</h2>
                <p>Bowling Details</p>
                <table>
                  <thead>
                    <tr>
                      <th>Bowler</th>
                      <th>Overs</th>
                      <th>Runs</th>
                      <th>Wickets</th>
                      <th>Economy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scorecardData1.bowlersData.map((bowler, index) => (
                      <tr key={index}>
                        <td>{bowler.name}</td>
                        <td>{bowler.overs}</td>
                        <td>{bowler.runs}</td>
                        <td>{bowler.wickets}</td>
                        <td>{bowler.economy}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
            )}
            
          </div>
        )}

{activeTab === "squads-info" && squadsInfoData && matchStatus!="pending" && (
  <div className="squads-info-tab">
       <div className="team left-team">
      <h2>{scorecardData.teamName}</h2>
      <ul className="players-list">
        {squadsInfoData.teamA.map((player, index) => (
          <li className="player" key={index}>
            <img src={player.image} alt="Player" />
            <div>
              <span className="player-name">{player.name}</span>
              <span className="player-info">({player.role})</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
    <div className="separator"></div>
    <div className="team right-team">
      <h2>{scorecardData1.teamName}</h2>
      <ul className="players-list">
        {squadsInfoData.teamB.map((player, index) => (
          <li className="player" key={index}>
            <img src={player.image} alt="Player" />
            <div>
              <span className="player-name">{player.name}</span>
              <span className="player-info">({player.role})</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
   
  </div>
)}


        {activeTab === "match-summary" && matchSummaryData && (
          <div className="match-summary-tab">
            <p>{matchSummaryData.someValue}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MatchDetailsPage;
