import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './tournament-list.css'; // Import CSS file for styling

export function TournamentList() {
    const [tournaments, setTournaments] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                const token = localStorage.getItem('Authorization');
                const response = await axios.get('https://devapi.scoreboardmaster.com/api/tournament/list?self=true', {
                    headers: {
                       Authorization: `${token}`
                  }
                }); // Replace with your actual backend API endpoint
                setTournaments(response.data.data.tournaments);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching tournaments:', error);
                setError('Failed to fetch tournaments');
            }
        };

        fetchTournaments();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    const defaultThumbnailUrl = 'https://via.placeholder.com/300'; // Default thumbnail image URL

    const handleTournamentClick = (tournamentId) => {
        navigate(`/my-tournament-detail/${tournamentId}`); // Navigate to tournament details page with tournament ID using navigate
    };

    return (
        <div className="tournament-list-container">
            <h2>My Tournaments</h2>
            {tournaments.length === 0 ? (
                <div className="not-found-page">
                    <div className="not-found-icon">🏏</div> {/* Add an icon or use an image */}
                    <h3>No tournaments found</h3>
                    <p>There are no tournaments available. Create a new tournament to get started.</p>
                    <Link to="/create-tournament" className="create-tournament-link">Create Tournament</Link>
                </div>
            ) : (
                <div className="tournament-list">
                    {tournaments.map(tournament => (
                        <div className="tournament-card" key={tournament.id} onClick={() => handleTournamentClick(tournament.tournamentId)}>
                            <img src={tournament.thumbnailImage || defaultThumbnailUrl} alt={tournament.name} />
                            <div className="tournament-details">
                                <h3>{tournament.name}</h3>
                                <p>Organized By: {tournament.organizedBy}</p>
                                <p>Ground Name: {tournament.groundName}</p>
                                <p>Address: {tournament.address}</p>
                                <p>Sports Type: {tournament.gameType}</p>
                                <p>Start Date: {new Date(tournament.startDate * 1000).toLocaleDateString()}</p>
                                <p>End Date: {new Date(tournament.endDate * 1000).toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
