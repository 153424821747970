import "./Banner.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import {useEffect} from "react";
import { useState } from "react";
import TournamentDetailsPage from '../tournamentDetail/tournamentDetails';


function Banner({original_title, title, year, genre, description, img, idm, mediaType, tournamentId }) {
  const[Status,setStatus]=useState(false);
  const[wishid,setWishid]=useState("");
  const {id, category} = useParams();
  const [ wishdata , setWishData ] = useState([])
  const navigate = useNavigate();

  async function getWishlist(){
    const user = localStorage.getItem('user')
    // if(user){
    //   // const {token} = JSON.parse(user)
    //   // // const a = await fetch('http://localhost:7000/watchlist',{
    //   // const a = await fetch('https://hotstar-v.herokuapp.com/watchlist',{
    //   //     method : "GET",
    //   //     headers : {
    //   //       "content-type" : "application/json",
    //   //       Authentication : `Bearer ${token}`
    //   //     }
    //   //   })
    //   //   const b = await a.json()
    //   //   setWishData(b);
    //   setWishData([])

    // }else{
    //   setWishData([])
    // }
    
    }
useEffect(()=>{ getWishlist()},[])

useEffect(()=>{
  for(let i=0;i<wishdata.length;i++){
    if(original_title==wishdata[i].title){
      setStatus(true);
      setWishid(wishdata[i]._id)
    }
  }
},[wishdata]);

useEffect(()=>{
  setStatus(false)
},[id]);

const handleBannerClick = () => {
  const tournamentDetails = {
    original_title,
    title,
    year,
    genre,
    description,
    img,
    idm,
    mediaType,
    tournamentId
  };
  // Navigate to the details page with tournament data
  console.log('Banner clicked with tournament details:', tournamentDetails);
  // Navigate to the details page with tournament data
  // Replace this with your actual navigation logic
  navigate('/tournament-detail', { state: { tournament: tournamentDetails } })
  // <TournamentDetailsPage tournament={tournamentDetails} />
};

  async function addWatchList(){
    setStatus(true);
    const user = localStorage.getItem('user')
    if(user)
    {
      const {token} = JSON.parse(user)
      // const a = await fetch('http://localhost:7000/watchlist',{
      const a = await fetch('http://localhost:7000/watchlist',{
        method : "POST",
        body : JSON.stringify({
          id:id,
          imageUrl : img,
          title :title,
          overview : description
        }),
        headers : {
          "content-type" : "application/json",
          Authentication : `Bearer ${token}`
        }
      });
      
      const b = await a.json()
      setWishid(b._id)
      setStatus(true)
    }
    else
      {
        alert('Please SignIn to add this movie in your watchlist')
      }

  }

   async function deleteWatchList(){
     setStatus(false);
    {
      // const a = await fetch(`http://localhost:7000/watchlist/${wishid}`,{
      const a = await fetch(`https://hotstar-v.herokuapp.com/watchlist/${wishid}`,{
      
        method : "DELETE",
      });
    }
  }

  return (
      <div className="banner-container" onClick={handleBannerClick}>
        <div className="banner-left">
          <div className="banner-details">
            <h1>{title}</h1>
            <div id="genre">
              <span> {genre}</span>
            </div>
            <p className="banner-descr">{description}</p>
          </div>
          {id ? (
            <div className="btns">
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="banner-right"
          style={{
            backgroundImage: `url(${img})`,
          }}
        >
          <div></div>
        </div>
      </div>
  );
}

export default Banner;
