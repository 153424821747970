import { AppBar, Box, Container, Toolbar, Typography } from '@material-ui/core'
import React from 'react'
import HorizontalStepper from './HorizontalStepper'
import { useLocation } from 'react-router-dom';

const StepperContainer = () => {

  const location = useLocation();
  const { match } = location.state

  return (

    <div>
      <AppBar position='fixed'>
        {/* <Toolbar>
          <Typography variant='h6'>ScoreBoardMaster</Typography>
        </Toolbar> */}
      </AppBar>
      <Container>
        <Box marginTop={10}>
          <HorizontalStepper match={match} />
        </Box>
      </Container>
    </div>
  )
}

export default StepperContainer
