import "./footer.css"
import {Link} from "react-router-dom"

function Footer() {
  return (
      <div className="mid">
    <div className="foot">
    <div className="foot-left">
    </div>
    
    {/* <div className="foot-mid">
        <div >Connect with us</div>
        <span><a href="https://facebook.com/DisneyPlusHotstar"><img src="https://cdn-icons-png.flaticon.com/128/1384/1384021.png"></img></a></span><span><a href="https://twitter.com/DisneyPlusHS"><img src="https://cdn-icons-png.flaticon.com/128/1384/1384049.png"></img></a></span>
        </div> */}
    </div>
        <p>© 2024 SCOREBOARDMASTER. All Rights Reserved. </p>
        </div>
        
        
        
  )
}

export default Footer

