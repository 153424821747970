import React, { useState ,useEffect } from 'react';
import { TextField, Checkbox, Button, Typography, Stepper, Step, StepLabel, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Paper, Slide, List, ListItem, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios'; // Import Axios for making API requests


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: 'auto',
    padding: '2rem',
  },
  formContainer: {
    marginBottom: '2rem',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  center: {
    textAlign: 'center',
  },
  paper: {
    padding: '2rem',
    marginBottom: '2rem',
    marginTop: '1rem',
    backgroundColor: '#808080', 
  },
  popup: {
    padding: '2rem',
    marginBottom: '2rem',
    marginTop: '4rem',
    width:"20rem",
    marginLeft : "30rem",
    backgroundColor: '#808080', 
  },
  slide: {
    width: '100%',
  },
}));

const HorizontalStepper = ({ match }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlayers, setSelectedPlayers] = useState({ team1: [], team2: [] });
  const [selectedRole, setSelectedRole] = useState("");
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamAPlayers, setTeamAPlayers] = useState([]);
  const [teamBPlayers, setTeamBPlayers] = useState([]);

  const steps = ['Team', 'Overs', 'Batting', 'Select Players', 'Players on pitch'];

  
  useEffect(() => {
    // Fetch players list for team A
    fetchPlayersList(match.teamAId)
      .then((players) => {
        setTeamAPlayers(players);
      })
      .catch((error) => {
        console.error('Error fetching players for team A:', error);
      });

    // Fetch players list for team B
    fetchPlayersList(match.teamBId)
      .then((players) => {
        setTeamBPlayers(players);
      })
      .catch((error) => {
        console.error('Error fetching players for team B:', error);
      });
  }, [match.teamA, match.teamB]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = {
    team1: match.teamA,
    team2: match.teamB,
    maxOver: match.totalOvers,
    batting: '',
  };

  const fetchPlayersList = async (teamId) => {
    try {
      const token = localStorage.getItem('Authorization');
      const response = await axios.get(`https://devapi.scoreboardmaster.com/api/players/list`, {
      params: {
        teamId: teamId
      },
      headers: {
        'Authorization': token
      }
    });
    
      return response.data.data.players;
    } catch (error) {
      throw error;
    }
  };


  const startMatch = async (requestData) => {
    try {
      const token = localStorage.getItem('Authorization');
      const response = await axios.post(`https://devapi.scoreboardmaster.com/api/match/start`, requestData, {
        headers: {
          'Authorization': token,
        }
      });
      return response.data
    } catch (error) {
      throw error;
    }
  };

  const validationSchema = [
    Yup.object().shape({
      team1: Yup.string().required('Team Name is required'),
      team2: Yup.string().required('Team Name is required'),
    }),
    Yup.object().shape({
      maxOver: Yup.string().required('Over is required'),
    }),
    Yup.object().shape({
      batting: Yup.string().required('Please choose who is Batting'),
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  function isLastStep() {
    return activeStep === steps.length - 1;
  }

  const handlePlayerSelectStep4 = (team, player) => {
    setSelectedPlayers((prevState) => ({
      ...prevState,
      [team]: [...(prevState[team] || []), player], // Ensure prevState[team] is an array
    }));
  };
  

  const handlePlayerSelectStep5 = (player) => {
    setSelectedPlayers((prevState) => ({
      ...prevState,
      [selectedRole]: player,
    }));
  };

  const PlayerSelectionModal = ({ players, handlePlayerSelect }) => {
    const classes = useStyles();
  
    return (
      <div className={classes.popup}>
        <Typography variant="h6" className={classes.center}>Select {selectedRole}:</Typography>
        <List>
          {players.map((player) => (
            <ListItem key={player.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPlayers[selectedRole] === player}
                    onChange={() => handlePlayerSelect(player)}
                    name={player.name}
                    color="primary"
                  />
                }
                label={player.name}
              />
            </ListItem>
          ))}
        </List>
        <div className={classes.center}>
          <Button variant="contained" color="primary" onClick={() => setIsModalOpen(false)}>Close</Button>
        </div>
      </div>
    );
  };
  

  const handleSelectStriker = (values) => {
    setSelectedRole("striker");
    const battingTeamPlayers = values.batting === values.team1 ? selectedPlayers.team1 : selectedPlayers.team2;
    setAvailablePlayers(battingTeamPlayers);
    setIsModalOpen(true);
  };
  
  const handleSelectNonStriker = (values) => {
    setSelectedRole("nonStriker");
    const battingTeamPlayers = values.batting === values.team1 ? selectedPlayers.team1 : selectedPlayers.team2;
    // Filter out the selected striker from available players
    setAvailablePlayers(battingTeamPlayers.filter(player => player !== selectedPlayers.striker));
    setIsModalOpen(true);
  };
  
  const handleSelectBowler = (values) => {
    setSelectedRole("bowler");
    const bowlingTeamPlayers = values.batting === values.team1 ? selectedPlayers.team2 : selectedPlayers.team1;
    setAvailablePlayers(bowlingTeamPlayers);
    setIsModalOpen(true);
  };
  
  const PlayerList = ({ teamName, team, players }) => (
    <Slide direction="left" in={activeStep === 3} mountOnEnter unmountOnExit>
      <div className={classes.slide}>
      <Typography variant="h6" gutterBottom>
  {teamName} Players
</Typography>
<List>
  {players.map((player) => (
    <ListItem key={player.id}>
      <FormControlLabel
        control={
          <Checkbox
            checked={(selectedPlayers[team] || []).some(selectedPlayer => selectedPlayer.name === player.name)}
            onChange={() => handlePlayerSelectStep4(team, player)}
            name={player.name}
            color="primary"
          />
        }
        label={player.name}
      />
    </ListItem>
  ))}
</List>
      </div>
    </Slide>
  );

  return (
    <div className={classes.mainContainer}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.formContainer}>
        <Formik
  enableReinitialize
  validationSchema={currentValidationSchema}
  initialValues={initialValues}
  onSubmit={(values, actions) => {
    handleNext();
    actions.setTouched({});
    actions.setSubmitting(false);
    if (isLastStep()) {
      const data = JSON.stringify({
        ...values,
        selectedPlayers: {
          striker: selectedPlayers.striker,
          nonStriker: selectedPlayers.nonStriker,
          bowler: selectedPlayers.bowler,
        },
      });

      let requestData = {
        selectedPlayers: {
          striker: selectedPlayers.striker,
          nonStriker: selectedPlayers.nonStriker,
          bowler: selectedPlayers.bowler,
        },
        toss : values.batting,
        matchId : match.gameId,
        teamAplayerList : selectedPlayers.team1,
        teamBplayerList :selectedPlayers.team2
      }
      startMatch(requestData)
      localStorage.setItem('data', data);
      setTimeout(() => {
      navigate('/score', { state: { matchData : match }});
      }, 1000);
    }
  }}
>
            {(props) => {
              const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
              return (
                <form onSubmit={handleSubmit}>
                  {activeStep === 0 && (
                    <div className={classes.formGroup}>
                      <TextField
                        id='team1'
                        name='team1'
                        label='Team1 Name*'
                        value={values.team1}
                        onChange={handleChange}
                        disabled
                        onBlur={handleBlur}
                        error={!!(errors.team1 && touched.team1)}
                        helperText={errors.team1 && touched.team1 && errors.team1}
                        fullWidth
                      />
                      <Typography variant="h5" className={classes.center}>VS</Typography>
                      <TextField
                        id='team2'
                        name='team2'
                        label='Team2 Name*'
                        value={values.team2}
                        onChange={handleChange}
                        disabled
                        onBlur={handleBlur}
                        error={!!(errors.team2 && touched.team2)}
                        helperText={errors.team2 && touched.team2 && errors.team2}
                        fullWidth
                      />
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div className={classes.formGroup}>
                      <TextField
                        id='maxOver'
                        name='maxOver'
                        label='Overs*'
                        value={values.maxOver}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.maxOver && touched.maxOver)}
                        helperText={errors.maxOver && touched.maxOver && errors.maxOver}
                        fullWidth
                      />
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div className={classes.formGroup}>
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>Who is Batting?</FormLabel>
                        <RadioGroup
                          name='batting'
                          value={values.batting.toString()}
                          onChange={(event) => {
                            setFieldValue('batting', event.currentTarget.value);
                          }}
                        >
                          <FormControlLabel value={values.team1} control={<Radio />} label={values.team1} />
                          <FormControlLabel value={values.team2} control={<Radio />} label={values.team2} />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
          
{activeStep === 3 && (
  <React.Fragment>
    <PlayerList teamName={values.team1} team="team1" players={teamAPlayers} />
    <PlayerList teamName={values.team2} team="team2" players={teamBPlayers} />
  </React.Fragment>
)}

{activeStep === 4 && (
  <div>
    <Button variant="contained" color="primary" onClick={() => handleSelectStriker(values)}>
      Select Striker
    </Button>
    <div style={{ marginTop: '1rem' }}></div>
    <Button variant="contained" color="primary" onClick={() => handleSelectNonStriker(values)}>
      Select Non-Striker
    </Button>
    <div style={{ marginTop: '1rem' }}></div>
    <Button variant="contained" color="primary" onClick={() => handleSelectBowler(values)}>
      Select Bowler
    </Button>
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <PlayerSelectionModal players={availablePlayers} handlePlayerSelect={handlePlayerSelectStep5} />
    </Modal>
    <div style={{ marginTop: '1rem' }}></div>
  </div>
)}

                  <div>
                    <Button
                      variant='contained'
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button variant='contained' color='primary' type='submit'>
                      {isLastStep() ? 'Start Game' : 'Next'}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Paper>
    </div>
  );
};

export default HorizontalStepper;

