import React, { useState , useEffect} from 'react';
import './tournamentDetails.css'; // Import CSS file for styling
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making API requests
import PlayerListModal from '../TeamInfo/PlayerListModel';

function TournamentDetailsPage() {
  // Get tournament data from location
  const location = useLocation();
  const { tournament } = location.state || {};
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]); // State to hold teams data
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [tournamentData, setTournamentData] = useState(false);



  useEffect(() => {
    // Fetch tournament details
    const token = localStorage.getItem('Authorization');
    axios.get('https://devapi.scoreboardmaster.com/api/tournament/profile/' + tournament.tournamentId)
      .then(response => {
        setTournamentData(response.data.data.tournament);
      })
      .catch(error => {
        console.error('Error fetching tournament details:', error);
        setTournamentData({}); // Set empty object if API fails
      });

    // Fetch tournament  Matches
    axios.get('https://devapi.scoreboardmaster.com/api/match/list/')
      .then(response => {
        setMatches(response.data.data.games);
      })
      .catch(error => {
        console.error('Error fetching player stats:', error);
        setMatches([]); // Set empty object if API fails
      });
      // Fetch teams data
    axios.get('https://devapi.scoreboardmaster.com/api/teams/list',{
      headers: {
          'Authorization': token,
      }
    })
    .then(response => {
      setTeams(response.data.data.teams);
    })
    .catch(error => {
      console.error('Error fetching teams:', error);
      setTeams([]); // Set empty array if API fails
    });

  },[]);
  
  // Define tabs and their corresponding content
  const tabs = [
    { id: 'matches', label: 'Matches', content: renderMatches },
    { id: 'stats', label: 'Stats', content: renderStats },
    { id: 'teams', label: 'Teams', content: renderTeams },
    { id: 'info', label: 'Info', content: renderInfo }
  ];

  // Define sub-tabs data for the "Stats" tab
  const statSubTabs = [
    { id: 'topBowlers', label: 'Top Bowlers', content: renderTopBowlers },
    { id: 'topFielders', label: 'Top Fielders', content: renderTopFielders },
    { id: 'topAllRounders', label: 'Top All-rounders', content: renderTopAllRounders }
  ];

  const dummyStats = {
    topBowlers: [
      { id: 1, name: 'Player X' },
      { id: 2, name: 'Player Y' },
      { id: 3, name: 'Player Z' },
      { id: 4, name: 'Player Z' },
      { id: 5, name: 'Player Z' },
      { id: 6, name: 'Player Z' },
      { id: 7, name: 'Player Z' },
      { id: 8, name: 'Player Z' },
      { id: 9, name: 'Player Z' },
      { id: 10, name: 'Player Z' },
      { id: 11, name: 'Player Z' },
      { id: 12, name: 'Player Z' },
      { id: 13, name: 'Player Z' },
      { id: 14, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ],
    topFielders: [
      { id: 4, name: 'Player A' },
      { id: 5, name: 'Player B' },
      { id: 6, name: 'Player C' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ],
    topAllRounders: [
      { id: 7, name: 'Player P' },
      { id: 8, name: 'Player Q' },
      { id: 9, name: 'Player R' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ]
  };

  // State to manage active tab
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [activeSubTab, setActiveSubTab] = useState(statSubTabs[0].id);
  // Render tab content based on active tab
function renderActiveTabContent() {
    switch (activeTab) {
      case 'matches':
        return renderMatches();
      case 'stats':
        return renderStats();
      case 'teams':
        return renderTeams();
      case 'info':
        return renderInfo();
      default:
        return null;
    }
  }

  // Render active sub-tab content based on active sub-tab
  function renderActiveSubTabContent() {
    const activeSubTabContent = statSubTabs.find(tab => tab.id === activeSubTab)?.content;
    return activeSubTabContent ? activeSubTabContent() : null;
  }

  const convertEpochToDate = (epochInSeconds) => {
    const date = new Date(epochInSeconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // You can customize the format as needed
  };


  function renderMatches() {
    const navigateToMatchDetail = (matchId) => {
      navigate(`/match/${matchId}`); // Navigate to match detail page with match ID
    };
  if(matches.length > 0 ){
    for (let index = 0; index < matches.length; index++) {
      const element = matches[index];

      const scoringTeam = element.toss === element.teamA ? element.teamA : element.teamB
      const chessingTeam = scoringTeam === element.teamA ? element.teamB : element.teamA


  let winningMessage = `${element.inningNo === 1 ? scoringTeam : chessingTeam} needs ${element.remainingRuns} ${
    element.remainingRuns <= 1 ? 'run' : 'runs'
  } in ${element.remainingBalls} ${element.remainingBalls <= 1 ? 'ball' : 'balls'} to win`
   
 
  if (element.inningNo === 2) {
    if (element.teams[1].totalWickets < 10 && element.teams[1].overCount <= parseInt(element.totalOvers) && element.teams[1].totalScore >= (element.teams[0].totalScore + 1)) {
      winningMessage = `${chessingTeam} won by ${10 - element.teams[1].totalWickets} wickets`
    }
 
    if ((element.teams[1].totalWickets >= 10 || element.teams[1].overCount >= parseInt(element.totalOvers)) && element.teams[1].totalScore < (element.teams[0].totalScore + 1) - 1) {
      winningMessage = `${scoringTeam} won by ${(element.teams[0].totalScore + 1) - element.teams[1].totalScore - 1} runs`
    }
    if (element.teams[1].totalWickets < 10 && element.teams[1].overCount === parseInt(element.totalOvers) && element.teams[1].totalScore === (element.teams[0].totalScore + 1) - 1) {
      winningMessage = 'Match Tied'
    }
   }
   element.winningMessage = winningMessage
    }
  } 
  
    return (
      <div className="matches-container">
        {matches.length > 0 ? (
          matches.map(match => (
            <div key={match.id} className="match-card" onClick={() => navigateToMatchDetail(match.gameId)}>
              <div className="match-content">
                <div className="match-header">
                  <h3 className="match-teams">{match.teamA} vs {match.teamB}</h3>
                </div>
                <div className="match-details">
                  <h6 className="match-status">
                    {match.gameStatus === "ongoing" ? "Live" : match.gameStatus === "pending" ? 
                    `Match starts at : ${convertEpochToDate(match.insertDate)}` : 
                    match.gameStatus === "completed" ? match.winningMessage : convertEpochToDate(match.insertDate)}
                  </h6>
                </div>
              </div>
              <div className="match-icon">
                <span role="img" aria-label="Cricket bat">🏏</span>
              </div>
            </div>
          ))
        ) : (
          <div className="no-matches">
            <p>No matches found</p>
          </div>
        )}
      </div>
    );
  }






   // Render stats tab content
   function renderStats() {
    return (
      <div>
        <div className="tournament-details-subtabs">
          {statSubTabs.map(tab => (
            <button
              key={tab.id}
              className={activeSubTab === tab.id ? 'active' : ''}
              onClick={() => setActiveSubTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {renderActiveSubTabContent()}
      </div>
    );
  }
  const openPlayerModal = (team) => {
    setSelectedTeam(team);
    setTeamPlayers(team.players);
    setIsPlayerModalOpen(true);
  };

  // Function to close player list modal
  const closePlayerModal = () => {
    setIsPlayerModalOpen(false);
  };

  function renderTeams() {
    return teams.length > 0 ? (
      <div className="tournament-details-teams-list">
        <h2>Teams</h2>
        <div className="team-cards">
          {teams.map((team, index) => (
            <div className="team-card" key={index} onClick={() => openPlayerModal(team)}>
              <h3>{team.name}</h3>
            </div>
          ))}
        </div>
        <PlayerListModal
         teamId={selectedTeam.teamId}
          isOpen={isPlayerModalOpen}
          onClose={closePlayerModal}
          teamName={selectedTeam.name}
          players={teamPlayers}
          addPlayerRequired={false}
        />
      </div>
    ) : (
      <p>No teams found</p>
    );
  }

  function renderInfo() {
    return tournamentData ? (
      <div className="tournament-details-info">
        <h2>Tournament Information</h2>
        <p>Organized By: {tournamentData.organizedBy}</p>
        <p>Venue: {tournamentData.address}</p>
        <p>Start Date: {convertEpochToDate(tournamentData.startDate)}</p>
        <p>End Date: {convertEpochToDate(tournamentData.endDate)}</p>
      </div>
    ) : (
      <p>No tournament information found</p>
    );
  }


// Render top bowlers content
function renderTopBowlers() {
  return (
    <div className="top-players">
      {dummyStats.topBowlers.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Wickets:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Overs:</span> <span className="stat-value">{getRandomNumber(10, 50)}</span><br />
              <span className="stat-label">Economy:</span> <span className="stat-value">{getRandomDecimal(4, 10)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}

// Render top fielders content
function renderTopFielders() {
  return (
    <div className="top-players">
      {dummyStats.topFielders.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Catches:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Run Outs:</span> <span className="stat-value">{getRandomNumber(2, 10)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}

// Render top all-rounders content
function renderTopAllRounders() {
  return (
    <div className="top-players">
      {dummyStats.topAllRounders.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Runs:</span> <span className="stat-value">{getRandomNumber(100, 500)}</span><br />
              <span className="stat-label">Wickets:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Fours:</span> <span className="stat-value">{getRandomNumber(20, 100)}</span><br />
              <span className="stat-label">Sixes:</span> <span className="stat-value">{getRandomNumber(10, 50)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}
  
  // Helper function to generate random integer within a range
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  // Helper function to generate random decimal within a range
  function getRandomDecimal(min, max) {
    return (Math.random() * (max - min) + min).toFixed(2);
  }
  
  // Function to handle navigation to player profile page
  function navigateToPlayerProfile(playerId) {
    // Implement navigation logic to player profile page
  }
  
  return (
    <div className="tournament-details-container">
      <div className="banner-container">
        <div className="banner-left">
          <div className="banner-details">
            <h1>{tournamentData.name}</h1>
            <div id="genre">
              <span>{tournamentData.gameType}</span>
            </div>
            <p className="banner-descr">{tournamentData.description}</p>
          </div>
        </div>
        <div
          className="banner-right"
          style={{
            backgroundImage: `url(${tournamentData.thumbnailImage})`,
          }}
        >
          <div></div>
        </div>
      </div>
      <div className="tournament-details-content">
        <div className="tournament-details-tabs">
          {/* Render tabs */}
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={activeTab === tab.id ? 'active' : ''}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {/* Render active tab content */}
        {renderActiveTabContent()}
      </div>
    </div>
  );
}

export default TournamentDetailsPage;
