// PlayerListModal.js
import React, { useState } from 'react';
import "./playerListModel.css";
import axios from 'axios'; // Import Axios for making API requests

function PlayerListModal({ teamId, isOpen, onClose, teamName, players, onAddPlayer , addPlayerRequired  }) {
  const [showAddPlayerForm, setShowAddPlayerForm] = useState(false);
  const [showAddPlayerButton, setShowAddPlayerButton] = useState(addPlayerRequired);
  const [newPlayerName, setNewPlayerName] = useState('');
  const [address, setAddress] = useState('');
  const [jerseyNumber, setJerseyNumber] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [dob, setDob] = useState('');
  const [playerRole, setPlayerRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const handleAddPlayer = async () => {
    if (newPlayerName.trim() !== '') {
      setIsLoading(true);
      try {
        // Make API call to add player
        const token = localStorage.getItem("Authorization");


         // Create FormData object to upload file
         const formData = new FormData();
         formData.append('image', profilePic);
 
         // Upload thumbnail image
         const thumbnailUploadResponse = await axios.post('https://devapi.scoreboardmaster.com/api/s3/image-upload', formData, {
             headers: {
                 Authorization: `${token}`,
                 'Content-Type': 'multipart/form-data',
             },
         });

        const response = await axios.post('https://devapi.scoreboardmaster.com/api/players/create', {
            name: newPlayerName,
            teamId: teamId,
            address: address,
            jerseyNumber: jerseyNumber,
            profilePic: thumbnailUploadResponse.data.data.url,
            dob: dob,
            playerRole: playerRole,
          },
          {
            headers: {
              'Authorization': token,
            },
          }
        );
        if (response.data.statusCode === 200) {
          // Player added successfully
          setNewPlayerName('');
          setAddress('');
          setJerseyNumber('');
          setProfilePic('');
          setDob('');
          setPlayerRole('');
          setError(null); // Reset error state
          setShowAddPlayerForm(false)
          onClose();
        } else {
          // Handle backend errors
          const errorData = response.data.message || 'Failed to add player';
          setError(errorData);
          setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
        }
      } catch (error) {
        console.error('Error adding player:', error);
        setError('Failed to add player. Please try again later.');
        setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
      }
      setIsLoading(false);
    }
  };

  const defaultPlayerImg = "https://via.placeholder.com/150"; // Default player image URL

  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          {!showAddPlayerForm && (
            <div>
              <h2>Players for {teamName}</h2>
              <ul>
                {players.map((player, index) => (
                  <li key={index}>
                    <div className="player-item">
                      <img src={player.profilePic || defaultPlayerImg} alt={player.name} className="player-img" />
                      <div className="player-info">
                        <h3>{player.name}</h3>
                        <p>{player.playerRole}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {showAddPlayerButton && (
                <button className="add-player-button" onClick={() => setShowAddPlayerForm(true)}>Add Player</button>
              )}
              
            </div>
          )}
          {showAddPlayerForm && (
            <div className="add-player-form">
              <h2>Add New Player</h2>
              <label htmlFor="profilePic">Name</label>
              <input
                type="text"
                placeholder="Enter player name"
                value={newPlayerName}
                onChange={(e) => setNewPlayerName(e.target.value)}
              />
              <label htmlFor="profilePic">Address</label>
              <input
                type="text"
                placeholder="Enter address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label htmlFor="profilePic">Jersey Number</label>
              <input
                type="text"
                placeholder="Enter Jersey Number"
                value={jerseyNumber}
                onChange={(e) => setJerseyNumber(e.target.value)}
              />
              <label htmlFor="profilePic">Select profile picture</label>
<input
  type="file"
  id="profilePic"
  placeholder="Add Profile URL"
  accept="image/*"
  onChange={(e) => setProfilePic(e.target.files[0])}
/>
<label htmlFor="profilePic">DOB</label>
              <input
                type="text"
                placeholder="Enter DOB"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
                        <label htmlFor="playerRole">Select Role:</label>
                        <div className="roledropdown">
                            <select id="playerRole" name="playerRole" value={playerRole}  onChange={(e) => setPlayerRole(e.target.value)} required>
                                <option value="Batter">Batter</option>
                                <option value="Bowler">Bowler</option>
                                <option value="All Rounder">All Rounder</option>
                                <option value="Wicket Keeper">Wicket Keeper</option>
                            </select>
                        </div>
              <button onClick={handleAddPlayer} disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Submit'}
              </button>
              <button onClick={() => setShowAddPlayerForm(false)}>Cancel</button>
              {error && <p className="error-message">{error}</p>}
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default PlayerListModal;
