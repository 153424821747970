import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaPencilAlt } from 'react-icons/fa';
import "./profile.css";

export function Profile() {
    const [userData, setUserData] = useState(null);
    const [editing, setEditing] = useState(false);
    const [updatedName, setUpdatedName] = useState('');
    const [updatedProfilePic, setUpdatedProfilePic] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const token = localStorage.getItem('Authorization');
                const response = await axios.get('https://devapi.scoreboardmaster.com/api/users/profile', {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `${token}`
                    }
                });
                setUserData(response.data.data);
                setUpdatedName(response.data.data.firstName);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleSaveClick = async () => {
        try {
            const token = localStorage.getItem('Authorization');
            let updateData = {}

         const formData = new FormData();
         formData.append('image', updatedProfilePic);
 
         // Upload thumbnail image
         const thumbnailUploadResponse = await axios.post('https://devapi.scoreboardmaster.com/api/s3/image-upload', formData, {
             headers: {
                 Authorization: `${token}`,
                 'Content-Type': 'multipart/form-data',
             },
         });
            updateData.firstName = updatedName
            updateData.profilePic = thumbnailUploadResponse.data.data.url

            const response = await axios.put('https://devapi.scoreboardmaster.com/api/users/', updateData, {
                headers: {
                    Authorization: `${token}`
                }
            });

            setUserData(response.data.data);
            setEditing(false);
        } catch (error) {
            console.error('Error updating user profile:', error);
        }
    };

    const handleCancelClick = () => {
        setEditing(false);
    };

    const handleProfilePicChange = (e) => {
        setUpdatedProfilePic(e.target.files[0]);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="containerdiv">
            <div className="profile-card">
                <div className="profile-header">
                    <h1>Profile</h1>
                    {userData && (
                        <>
                            <div className="profile-img-container">
                                <img
                                    className="user-img"
                                    src={userData.profilePic ? userData.profilePic : "https://via.placeholder.com/100x100"}
                                    alt=""
                                />
                                {editing && (<FaPencilAlt className="edit-icon" onClick={triggerFileInput} />)}
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={handleProfilePicChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            {editing ? (
                                <div className="edit-form">
                                    <input
                                        type="text"
                                        value={updatedName}
                                        onChange={(e) => setUpdatedName(e.target.value)}
                                    />
                                    <button className="save-btn" onClick={handleSaveClick}>Save</button>
                                    <button className="cancel-btn" onClick={handleCancelClick}>Cancel</button>
                                </div>
                            ) : (
                                <>
                                    <h1 className="user-name">{userData.firstName}</h1>
                                    <h2 className="user-email">{userData.email}</h2>
                                    <button onClick={handleEditClick}>Edit</button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
