import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './tournament.css';
var map, marker;

export function TournamentForm() {
    const [tournamentData, setTournamentData] = useState({
        name: '',
        organizedBy: '',
        groundName: '',
        address: '',
        gameType: 'Cricket',
        startDate: '',
        endDate: '',
        description: '',
        thumbnailImage: null
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const addressInputRef = useRef(null);

    useEffect(() => {
        map = new window.mappls.Map('map', {
            center: [28.09, 78.3],
            zoom: 3,
            view: {
                pitch: 60 // Set the pitch value to control the 3D view angle
            }
        });
        map.addListener('load', function () {
            var optional_config = {
                region: "IND",
                height: 300,
            };
            if (addressInputRef.current) {
                new window.mappls.search(addressInputRef.current, optional_config, callback);
            }
            function callback(data) {
                if (data) {
                    var dt = data[0];
                    if (!dt) return false;
                    var eloc = dt.eLoc;
                    var place = dt.placeName + ", " + dt.placeAddress;
                    if (marker) marker.remove();
                    window.mappls.pinMarker({
                        map: map,
                        pin: eloc,
                        popupHtml: place,
                        popupOptions: {
                            openPopup: true
                        }
                    }, function (data) {
                        marker = data;
                        console.log(map,"map")
                        map.setZoom(15);
                        console.log(marker,"marker")
                        marker.fitbounds();
                    })
                }
            }
        });

    }, [addressInputRef]);

    const handleSubmit = async (e) => {
        setError("");
        e.preventDefault();
        try {
            const token = localStorage.getItem('Authorization');


            // Convert startDate and endDate to epoch seconds
        const startDateEpoch = Math.floor(new Date(tournamentData.startDate).getTime() / 1000);
        const endDateEpoch = Math.floor(new Date(tournamentData.endDate).getTime() / 1000);

        // Update tournamentData with epoch seconds
        const updatedTournamentData = {
            ...tournamentData,
            startDate: startDateEpoch,
            endDate: endDateEpoch
        };


            // Create FormData object to upload file
        const formData = new FormData();
        formData.append('image', tournamentData.thumbnailImage);

        // Upload thumbnail image
        const thumbnailUploadResponse = await axios.post('https://devapi.scoreboardmaster.com/api/s3/image-upload', formData, {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        // Extract thumbnail URL from the response
        const thumbnailUrl = thumbnailUploadResponse.data.data.url;

        // Update tournamentData with the thumbnail URL
        updatedTournamentData.thumbnailImage = thumbnailUrl;

            const response = await axios.post('https://devapi.scoreboardmaster.com/api/tournament/create', updatedTournamentData, {
                headers: {
                    Authorization: `${token}`
                }
            });
            console.log('Tournament created successfully:', response.data);
            setTournamentData({
                name: '',
                organizedBy: '',
                groundName: '',
                address: '',
                gameType: '',
                startDate: '',
                endDate: '',
                description: '',
                thumbnailImage: ''
            });
            navigate('/');
        } catch (error) {
            console.error('Error creating tournament:', error);
            setError(error.response.data.data.message);
        }
    };

    return (
        <div className="tournament-form-container">
            <h2>Add Tournament</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="name">Title:</label>
                        <input type="text" id="name" name="name" value={tournamentData.name} onChange={(e) => setTournamentData({ ...tournamentData, name: e.target.value })} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="organizedBy">Organized By:</label>
                        <input type="text" id="organizedBy" name="organizedBy" value={tournamentData.organizedBy} onChange={(e) => setTournamentData({ ...tournamentData, organizedBy: e.target.value })} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="groundName">Ground Name:</label>
                        <input type="text" id="groundName" name="groundName" value={tournamentData.groundName} onChange={(e) => setTournamentData({ ...tournamentData, groundName: e.target.value })} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="gameType">Sports Type:</label>
                        <div className="sportsdropdown">
                            <select id="gameType" name="gameType" value={tournamentData.gameType} onChange={(e) => setTournamentData({ ...tournamentData, gameType: e.target.value })} required>
                                <option value="Cricket">Cricket</option>
                                <option disabled>Football (Coming Soon)</option>
                                <option disabled>Kabaddi (Coming Soon)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="startDate">Start Date:</label>
                        <input type="date" id="startDate" name="startDate" value={tournamentData.startDate} onChange={(e) => setTournamentData({ ...tournamentData, startDate: e.target.value })} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="endDate">End Date:</label>
                        <input type="date" id="endDate" name="endDate" value={tournamentData.endDate} onChange={(e) => setTournamentData({ ...tournamentData, endDate: e.target.value })} required />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <input type="text" id="description" name="description" value={tournamentData.description} onChange={(e) => setTournamentData({ ...tournamentData, description: e.target.value })} required />
                </div> */}
                <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input type="address-text" id="address" name="address" ref={addressInputRef} value={tournamentData.address} onChange={(e) => setTournamentData({ ...tournamentData, address: e.target.value })} required />
                </div>
                <div id="map" style={{ width: '100%', height: '400px' }}></div>

                <div className="form-group">
                    <label htmlFor="thumbnail">Thumbnail Image:</label>
                    <input type="file" id="thumbnail" name="thumbnail" accept="image/*" onChange={(e) => setTournamentData({ ...tournamentData, thumbnailImage: e.target.files[0] })} required />
                </div>
                
                <button className="submit-button" type="submit">Add Tournament</button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
}
