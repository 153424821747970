import mqtt from 'mqtt';

let client = null;

export const connectToBroker = (brokerUrl) => {
  client = mqtt.connect(brokerUrl,{username:"scoreboardmaster",password:"scoreboardmaster@1234",connectTimeout :8000});
  client.on('connect', () => {
    console.log('Connected to MQTT broker');
  });
  return client;
};

export const subscribeToTopic = (topic, callback) => {
  client.subscribe(topic, (err) => {
    if (err) {
      console.error('Error subscribing to topic:', err);
    }
  });
  client.on('message', (topic, message) => {
    callback(topic, message.toString());
  });
};

export const publishMessage = (topic, message) => {
  client.publish(topic, message);
};

export const disconnectFromBroker = () => {
  client.end();
  console.log('Disconnected from MQTT broker');
};
