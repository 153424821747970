import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/footer";
// import IndividualPage from "./components/IndividualPage/IndividualPage";
import MainPage from "./components/MainPage/MainPage";
import Navbar from "./components/Navbar/Navbar";
import VideoPage from "./components/videoPage/VideoPage";
import { WatchList } from "./components/Watchlist/WatchList";
import {Profile} from "./components/Profile/Profile";
import {TournamentForm} from "./components/tournament/tournament";
import {TournamentList} from "./components/tournament-list/tournament-list";
import TournamentDetailsPage from "./components/tournamentDetail/tournamentDetails";
import MyTournamentDetailsPage from "./components/mytournamentDetail/mytournamentDetails";
import MatchDetailsPage from "./components/matchDetail/matchDetail";
import ScoreBoard from './components/scoreboard/ScoreBoard'
import StepperContainer from './components/scoreboard/StepperContainer';
import ContactForm from './components/Contact/contactForm'



function AllRoutes() {
  return (
    <>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<MainPage></MainPage>}></Route>
        <Route path="/:category" element={<MainPage ></MainPage>} ></Route>
        {/* <Route path="/:category/:language" element={<MainPage ></MainPage>} ></Route> */}
        {/* <Route  path={`/:category/:id`}  element={<IndividualPage  ></IndividualPage>} ></Route> */}
        <Route path="/:category/:id/video" element={<VideoPage></VideoPage>}></Route>
        <Route path="/watchlist" element={<WatchList/>}></Route>
        <Route path="/profile" element={<Profile/>}></Route>
        <Route path="/create-tournament" element={<TournamentForm/>}></Route>
        <Route path="/my-tournament" element={<TournamentList/>}></Route>
        <Route path="/tournament-detail" element={<TournamentDetailsPage/>}></Route>
        <Route path="/my-tournament-detail/:tournamentId" element={<MyTournamentDetailsPage/>}></Route>
        <Route path="/match/:id" element={<MatchDetailsPage/>}></Route>
        <Route exact path='/match-score' element={<StepperContainer/>} />
        <Route exact path='/score' element={<ScoreBoard/>} />
        <Route path="/contact-us" element={<ContactForm />} />
        
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default AllRoutes;
