import React, { useState, useEffect } from 'react';
import './addMatch.css';
import axios from 'axios'; 

function AddMatchModal({ tournamentId, onClose, onSubmit }) {
  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');
  const [ground, setGround] = useState('');
  const [overs, setOvers] = useState('');
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    // Fetch team list from backend API
    fetchTeamList();
  }, []);

  const fetchTeamList = async () => {
    try {
      const token = localStorage.getItem("Authorization");
      const response = await axios.get('https://devapi.scoreboardmaster.com/api/teams/list', {
        headers: {
          'Authorization': token,
        }
      });
      setTeamList(response.data.data.teams);
    } catch (error) {
      console.error('Error fetching teams:', error);
      setTeamList([]); // Set empty array if API fails
    }
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("Authorization");
      const response = await axios.post('https://devapi.scoreboardmaster.com/api/match/create', {
        teamA: team1,
        teamB: team2,
        groundName : ground,
        totalOvers : overs,
        tournamentId :tournamentId
      }, {
        headers: {
          'Authorization': token,
        }
      });
      onSubmit(response.data); // Assuming your onSubmit function handles the response
      onClose();
    } catch (error) {
      console.error('Error adding match:', error);
      // Handle error
    }
  };

  return (
    <div className="modal-overlay">
      <div className="add-match-modal">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add Match</h2>
        <div className="input-group">
          <label>Team 1:</label>
          <select value={team1} onChange={e => setTeam1(e.target.value)}>
            <option value="">Select Team 1</option>
            {teamList.map(team => (
              <option key={team.teamId} value={team.teamId}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label>Team 2:</label>
          <select value={team2} onChange={e => setTeam2(e.target.value)}>
            <option value="">Select Team 2</option>
            {teamList.map(team => (
              <option key={team.teamId} value={team.teamId}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label>Ground:</label>
          <input type="text" value={ground} onChange={e => setGround(e.target.value)} />
        </div>
        <div className="input-group">
          <label>Overs:</label>
          <input type="number" value={overs} onChange={e => setOvers(e.target.value)} />
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default AddMatchModal;
