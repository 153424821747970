import './App.css';
import AllRoutes from './AllRoutes';

function App() {
  return (
    <div >
     <AllRoutes></AllRoutes>
    </div>
  );
}

export default App;
