import React, { useState } from 'react';
import './contactForm.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);

    try {
        const token = localStorage.getItem("Authorization");
        const response = await axios.post('https://devapi.scoreboardmaster.com/api/contact-us/', formData, {
          headers: {
            'Authorization': token,
          }
        });
        if(response.data.statusCode === 200){
            setFormData({
                name: '',
                email: '',
                message: ''
              })
          setSuccessMessage('Your query or feedback has been sent successfully!');
           setTimeout(() => {
          setSuccessMessage('');
          navigate("/");
        }, 4000); // Hide success message after 3 seconds
        }

        
      } catch (error) {
        console.error('Error submitted form data:', error);
        // Handle error
      }

    
  };

  return (
    <div className="contact-form-container">
       
      {/* <h2>Contact Us</h2> */}
      <form onSubmit={handleSubmit} className="contact-form">
      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="note">
        <p>Welcome! Feel free to reach out with any inquiries or provide feedback. We value your input and look forward to hearing from you.</p>
      </div>
        <div className="contact-form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
