// MainPage.js

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Banner from "../Banner/Banner";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CardRows from "../CardRows/CardRows";

function MainPage() {
  const { category, language } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [category, language]);

  const getData = async () => {
    try {
      // const token = localStorage.getItem('Authorization');
      const response = await axios.get('https://devapi.scoreboardmaster.com/api/tournament/list', {
          // headers: {
          //     Authorization: `${token}`
          // }
      }); // Replace with your actual backend API endpoint
      setData(response.data.data.tournaments);
  } catch (error) {
      console.error('Error fetching tournaments:', error);
  }
  };


  return (
    <div>
      <Carousel
        autoPlay
        interval={5000}
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        transitionTime={700}
        showThumbs={false}
        >
        {data.map((el, index) => (
          <Banner
          idm ={el.id}
          key={index}
          img={el.thumbnailImage}
          title={el.name || el.name}
          genre={el.gameType}
          description={el.description}
          tournamentId={el.tournamentId}
          mediaType = "image"
          />
          ))}
      </Carousel>
      <CardRows row_title={`Latest & Trending`} ></CardRows>
      {/* Render TournamentDetailsPage if a tournament is selected */}
      {/* {selectedTournament && <TournamentDetailsPage tournament={selectedTournament} />} */}
    </div>
  );
}

export default MainPage;
