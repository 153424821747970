import "./Card.css";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";

export const Card = ({ id, title, description, imageUrl, media_type, tournamentId }) => {
  const navigate = useNavigate();
 
  useEffect(() => {
  }, []);

  const handleBannerClick = () => {
    const tournamentDetails = {
      tournamentId
    };
    // Navigate to the details page with tournament data
    console.log('Card clicked with tournament details:', tournamentDetails);
    // Navigate to the details page with tournament data
    // Replace this with your actual navigation logic
    navigate('/tournament-detail', { state: { tournament: tournamentDetails } })
    // <TournamentDetailsPage tournament={tournamentDetails} />
  };
  return (
    // <Link to={`/${media_type || "movie"}/${id}`}>
    <div className="card-container" onClick={handleBannerClick}>
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
        className="row-card"
      >
        <div className="wrap">
          <div className="card-gradient">
            <div className="card-content">
              <h3>{title}</h3>
              <h3>{"cricket"}</h3>

              <p>{description} </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    // </Link>
  );
};

//discription : 'https://api.themoviedb.org/3/movie/852659?api_key=3e3f0a46d6f2abc8e557d06b3fc21a77&language=en-US'
