import React, { useState, useEffect,useRef } from 'react';
import './addTeamModal.css'; // Import CSS file for styling
import axios from 'axios'; // Import Axios for making API requests
var map, marker;

function AddTeamModal({ tournamentId ,onClose }) {
  const [teamName, setTeamName] = useState('');
  const [teamLogo, setTeamLogo] = useState('');
  const [teamAddress, setTeamAddress] = useState('');
  const [error, setError] = useState('');
  const addressInputRef = useRef(null);


  useEffect(() => {
    map = new window.mappls.Map('map', {
        center: [28.09, 78.3],
        zoom: 3,
        view: {
            pitch: 60 // Set the pitch value to control the 3D view angle
        }
    });
    map.addListener('load', function () {
        var optional_config = {
            region: "IND",
            height: 300,
        };
        if (addressInputRef.current) {
            new window.mappls.search(addressInputRef.current, optional_config, callback);
        }
        function callback(data) {
            if (data) {
                var dt = data[0];
                if (!dt) return false;
                var eloc = dt.eLoc;
                var place = dt.placeName + ", " + dt.placeAddress;
                if (marker) marker.remove();
                window.mappls.pinMarker({
                    map: map,
                    pin: eloc,
                    popupHtml: place,
                    popupOptions: {
                        openPopup: true
                    }
                }, function (data) {
                    marker = data;
                    console.log(map,"map")
                    map.setZoom(15);
                    console.log(marker,"marker")
                    marker.fitbounds();
                })
            }
        }
    });

}, [addressInputRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('Authorization');

            // Create FormData object to upload file
            const formData = new FormData();
            formData.append('image', teamLogo);
    
            // Upload thumbnail image
            const thumbnailUploadResponse = await axios.post('https://devapi.scoreboardmaster.com/api/s3/image-upload', formData, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

    
      // API call to submit team data
      const response = await axios.post('https://devapi.scoreboardmaster.com/api/teams/create', {
        name: teamName,
        logo: thumbnailUploadResponse.data.data.url,
        address: teamAddress,
        tournamentId :tournamentId
      },
      {
        headers: {
            'Authorization': token,
        }
    });
      console.log('Submitted team data:', response.data);
      // Reset form fields
      setTeamName('');
      setTeamLogo('');
      setTeamAddress('');
      // Close modal
      onClose();
    } catch (error) {
      console.error('Error submitting team data:', error);
      // Set error state to display on UI
      setError('Error submitting team data. Please try again.');
      // Clear error after 5 seconds
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <div className="modal-background">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>Add Team</h2>
        <form onSubmit={handleSubmit}>
          {error && <div className="error-message">{error}</div>}
          <div className="form-group">
            <label htmlFor="teamName">Name:</label>
            <input type="text" id="teamName" value={teamName} onChange={(e) => setTeamName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="teamLogo">Logo:</label>
            <input type="file" id="teamLogo"accept="image/*" onChange={(e) => setTeamLogo(e.target.files[0])} required />
          </div>
          <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input type="address-text" id="address" name="address" ref={addressInputRef} value={teamAddress} onChange={(e) => setTeamAddress(e.target.value)} required />
                </div>
                <div id="map" style={{ width: '100%', height: '200px' }}></div>
          <button type="submit" className="submit-button">Add Team</button>
        </form>
      </div>
    </div>
  );
}

export default AddTeamModal;
