import React, { useState, useEffect } from 'react';
import './mytournamentDetails.css'; // Import CSS file for styling
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making API requests
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddMatchModal from '../AddMatch/addMatch';
import PlayerListModal from '../TeamInfo/PlayerListModel'; 
import AddTeamModal from '../addTeam/AddTeam'; 


function MyTournamentDetailsPage() {
  // Get tournament data from location
  const { tournamentId } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [tournamentData, setTournamentData] = useState('');
  // const [playerStats, setPlayerStats] = useState('');
  const [matches, setMatches] = useState([]);
  const [activeTab, setActiveTab] = useState('matches');
  const [activeSubTab, setActiveSubTab] = useState('topBowlers');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teams, setTeams] = useState([]); // State to hold teams data
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [updateTournamentDetails, setUpdateTournamentDetails] = useState(false);



  useEffect(() => {
    // Fetch tournament details
    const token = localStorage.getItem('Authorization');
    axios.get('https://devapi.scoreboardmaster.com/api/tournament/profile/' + tournamentId)
      .then(response => {
        setTournamentData(response.data.data.tournament);
      })
      .catch(error => {
        console.error('Error fetching tournament details:', error);
        setTournamentData({}); // Set empty object if API fails
      });

    // Fetch tournament  Matches
    axios.get('https://devapi.scoreboardmaster.com/api/match/list/')
      .then(response => {
        setMatches(response.data.data.games);
      })
      .catch(error => {
        console.error('Error fetching player stats:', error);
        setMatches([]); // Set empty object if API fails
      });
      // Fetch teams data
    axios.get('https://devapi.scoreboardmaster.com/api/teams/list',{
      headers: {
          'Authorization': token,
      }
    })
    .then(response => {
      setTeams(response.data.data.teams);
    })
    .catch(error => {
      console.error('Error fetching teams:', error);
      setTeams([]); // Set empty array if API fails
    });

  },[updateTournamentDetails]);


 

  const openTeamModal = () => {
    setIsTeamModalOpen(true);
  };
  
  const closeTeamModal = () => {
    setIsTeamModalOpen(false);
  };

  // Function to open player list modal
  const openPlayerModal = (team) => {
    setSelectedTeam(team);
    setTeamPlayers(team.players);
    setIsPlayerModalOpen(true);
  };

  // Function to close player list modal
  const closePlayerModal = () => {
    setIsPlayerModalOpen(false);
    setUpdateTournamentDetails(true)
  };

  // Function to add a new player
  const handleAddPlayer = (newPlayerName) => {
    // Add logic to update the player list for the selected team
    const updatedPlayers = [...teamPlayers, newPlayerName];
    setTeamPlayers(updatedPlayers);
    setUpdateTournamentDetails(true)
    // Add API call or other logic to update the backend with the new player
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUpdateTournamentDetails(true)
  };

  const handleMatchSubmit = (matchData) => {
    // API call to submit match data
    console.log('Submitted match data:', matchData);
    setUpdateTournamentDetails(true)
  };

  // Define tabs and their corresponding content
  const tabs = [
    { id: 'matches', label: 'Matches', content: renderMatches },
    { id: 'stats', label: 'Stats', content: renderStats },
    { id: 'teams', label: 'Teams', content: renderTeams },
    { id: 'info', label: 'Info', content: renderInfo }
  ];

  // Define sub-tabs data for the "Stats" tab
  const statSubTabs = [
    { id: 'topBowlers', label: 'Top Bowlers', content: renderTopBowlers },
    { id: 'topFielders', label: 'Top Fielders', content: renderTopFielders },
    { id: 'topAllRounders', label: 'Top All-rounders', content: renderTopAllRounders }
  ];

  const playerStats = {
    topBowlers: [
      { id: 1, name: 'Player X' },
      { id: 2, name: 'Player Y' },
      { id: 3, name: 'Player Z' },
      { id: 4, name: 'Player Z' },
      { id: 5, name: 'Player Z' },
      { id: 6, name: 'Player Z' },
      { id: 7, name: 'Player Z' },
      { id: 8, name: 'Player Z' },
      { id: 9, name: 'Player Z' },
      { id: 10, name: 'Player Z' },
      { id: 11, name: 'Player Z' },
      { id: 12, name: 'Player Z' },
      { id: 13, name: 'Player Z' },
      { id: 14, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ],
    topFielders: [
      { id: 4, name: 'Player A' },
      { id: 5, name: 'Player B' },
      { id: 6, name: 'Player C' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ],
    topAllRounders: [
      { id: 7, name: 'Player P' },
      { id: 8, name: 'Player Q' },
      { id: 9, name: 'Player R' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },
      { id: 15, name: 'Player Z' },

    ]
  };



  function renderActiveTabContent() {
    switch (activeTab) {
      case 'matches':
        return renderMatches();
      case 'stats':
        return renderStats();
      case 'teams':
        return renderTeams();
      case 'info':
        return renderInfo();
      default:
        return null;
    }
  }

  // Render active sub-tab content based on active sub-tab
  function renderActiveSubTabContent() {
    const activeSubTabContent = statSubTabs.find(tab => tab.id === activeSubTab)?.content;
    return activeSubTabContent ? activeSubTabContent() : null;
  }
  
  const convertEpochToDate = (epochInSeconds) => {
    const date = new Date(epochInSeconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // You can customize the format as needed
  };



  function renderMatches() {
    const navigateToMatchDetail = (match) => {
      if (match.gameStatus === "pending") {
        navigate('/match-score', { state: { match } });
      } else {
        navigate('/score', { state: { matchData: match } });
      }
    };

    if(matches.length > 0 ){
      for (let index = 0; index < matches.length; index++) {
        const element = matches[index];
  
        const scoringTeam = element.toss === element.teamA ? element.teamA : element.teamB
        const chessingTeam = scoringTeam === element.teamA ? element.teamB : element.teamA
  
  
    let winningMessage = `${element.inningNo === 1 ? scoringTeam : chessingTeam} needs ${element.remainingRuns} ${
      element.remainingRuns <= 1 ? 'run' : 'runs'
    } in ${element.remainingBalls} ${element.remainingBalls <= 1 ? 'ball' : 'balls'} to win`
     
   
    if (element.inningNo === 2) {
      if (element.teams[1].totalWickets < 10 && element.teams[1].overCount <= parseInt(element.totalOvers) && element.teams[1].totalScore >= (element.teams[0].totalScore + 1)) {
        winningMessage = `${chessingTeam} won by ${10 - element.teams[1].totalWickets} wickets`
      }
   
      if ((element.teams[1].totalWickets >= 10 || element.teams[1].overCount >= parseInt(element.totalOvers)) && element.teams[1].totalScore < (element.teams[0].totalScore + 1) - 1) {
        winningMessage = `${scoringTeam} won by ${(element.teams[0].totalScore + 1) - element.teams[1].totalScore - 1} runs`
      }
      if (element.teams[1].totalWickets < 10 && element.teams[1].overCount === parseInt(element.totalOvers) && element.teams[1].totalScore === (element.teams[0].totalScore + 1) - 1) {
        winningMessage = 'Match Tied'
      }
     }
     element.winningMessage = winningMessage
      }
    } 
  
    return (
      <div className="matches-container">
        {matches.length > 0 ? (
          matches.map(match => (
            <div key={match.id} className="match-card" onClick={() =>navigateToMatchDetail(match)}>
              <div className="match-content">
                <div className="match-header">
                  <h3 className="match-teams">{match.teamA} vs {match.teamB}</h3>
                </div>
                <div className="match-details">
                <h6 className="match-status">
                    {match.gameStatus === "ongoing" ? "Live" : match.gameStatus === "pending" ? 
                    `Match starts at : ${convertEpochToDate(match.insertDate)}` : 
                    match.gameStatus === "completed" ? match.winningMessage : convertEpochToDate(match.insertDate)}
                  </h6>
                </div>
              </div>
              <div className="match-icon">
                <span role="img" aria-label="Cricket bat">🏏</span>
              </div>
            </div>
          ))
        ) : (
          <div className="no-matches">
            <p>No matches found</p>
          </div>
        )}
      </div>
    );
  }
  

   // Render stats tab content
   function renderStats() {
    return (
      <div>
        <div className="tournament-details-subtabs">
          {statSubTabs.map(tab => (
            <button
              key={tab.id}
              className={activeSubTab === tab.id ? 'active' : ''}
              onClick={() => setActiveSubTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {renderActiveSubTabContent()}
      </div>
    );
  }


function renderTeams (){
  return (
    <div className="tournament-details-teams-list">
      <h2>Teams</h2>
      <div className="team-cards">
        {teams.map((team, index) => (
          <div className="team-card" key={index} onClick={() => openPlayerModal(team)}>
            <h3>{team.name}</h3>
          </div>
        ))}
      </div>
      {/* Player list modal */}
      <PlayerListModal
       teamId={selectedTeam.teamId}
        isOpen={isPlayerModalOpen}
        onClose={closePlayerModal}
        teamName={selectedTeam.name}
        players={teamPlayers}
        onAddPlayer={handleAddPlayer}
        addPlayerRequired={true}
      />
    </div>
  );
};



  // Render info tab content
  function renderInfo() {
    return (
      <div className="tournament-details-info">
        <h2>Tournament Information</h2>
        <p>Organized By: {tournamentData.organizedBy}</p>
        <p>Venue: {tournamentData.address}</p>
        <p>Start Date: {tournamentData.startDate}</p>
        <p>End Date: {tournamentData.endDate}</p>
      </div>
    );
  }

  // Dummy data for each tab
  const dummyMatches = [
    { id: 1, teams: 'Team A vs Team B', result: 'Team A won by 5 wickets' },
    { id: 2, teams: 'Team C vs Team D', result: 'Match tied' },
    { id: 3, teams: 'Team E vs Team F', result: 'Team F won by 3 runs' },
    { id: 4, teams: 'Team G vs Team H', result: 'Team H won by 10 runs' },
    { id: 5, teams: 'Team I vs Team J', result: 'Team J won by 14 runs' },
    { id: 6, teams: 'Team K vs Team L', result: 'Team K won by 17 runs' },
    { id: 7, teams: 'Team M vs Team N', result: 'Team M won by 2 runs' },
    { id: 8, teams: 'Team O vs Team P', result: 'Team P won by 120 runs' },
    { id: 9, teams: 'Team Q vs Team R', result: 'Team Q won by 13 runs' },
    { id: 1, teams: 'Team A vs Team B', result: 'Team A won by 5 wickets' },
    { id: 2, teams: 'Team C vs Team D', result: 'Match tied' },
    { id: 3, teams: 'Team E vs Team F', result: 'Team F won by 3 runs' },
    { id: 4, teams: 'Team G vs Team H', result: 'Team H won by 10 runs' },
    { id: 5, teams: 'Team I vs Team J', result: 'Team J won by 14 runs' },
    { id: 6, teams: 'Team K vs Team L', result: 'Team K won by 17 runs' },
    { id: 7, teams: 'Team M vs Team N', result: 'Team M won by 2 runs' },
    { id: 8, teams: 'Team O vs Team P', result: 'Team P won by 120 runs' },
    { id: 9, teams: 'Team Q vs Team R', result: 'Team Q won by 13 runs' },


  ];

// Render top bowlers content
function renderTopBowlers() {
  return (
    <div className="top-players">
      {playerStats.topBowlers.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Wickets:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Overs:</span> <span className="stat-value">{getRandomNumber(10, 50)}</span><br />
              <span className="stat-label">Economy:</span> <span className="stat-value">{getRandomDecimal(4, 10)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}

// Render top fielders content
function renderTopFielders() {
  return (
    <div className="top-players">
      {playerStats.topFielders.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Catches:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Run Outs:</span> <span className="stat-value">{getRandomNumber(2, 10)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}

// Render top all-rounders content
function renderTopAllRounders() {
  return (
    <div className="top-players">
      {playerStats.topAllRounders.map((player, index) => (
        <div key={index} className="player-card">
          <img src={`https://via.placeholder.com/150`} alt="Player" className="player-image" />
          <div className="player-info">
            <span className="player-name">{player.name}</span>
            <div className="player-stats">
              <span className="stat-label">Runs:</span> <span className="stat-value">{getRandomNumber(100, 500)}</span><br />
              <span className="stat-label">Wickets:</span> <span className="stat-value">{getRandomNumber(5, 20)}</span><br />
              <span className="stat-label">Fours:</span> <span className="stat-value">{getRandomNumber(20, 100)}</span><br />
              <span className="stat-label">Sixes:</span> <span className="stat-value">{getRandomNumber(10, 50)}</span>
            </div>
          </div>
          <button className="details-button" onClick={() => navigateToPlayerProfile(player.id)}>Details</button>
        </div>
      ))}
    </div>
  );
}
  
  // Helper function to generate random integer within a range
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  // Helper function to generate random decimal within a range
  function getRandomDecimal(min, max) {
    return (Math.random() * (max - min) + min).toFixed(2);
  }
  
  // Function to handle navigation to player profile page
  function navigateToPlayerProfile(playerId) {
    // Implement navigation logic to player profile page
  }

  const handleCloseTeamModal = () => {
    setUpdateTournamentDetails(true)
    setIsTeamModalOpen(false);
  };

const dummyTeams = [
  {name : 'Team 1', players : [{name:"Surender", role : "Batter" , img : ""},{name:"Surender", role : "Batter" , img : ""},{name:"Surender", role : "Batter" , img : ""}]}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []},
  {name : 'Team 1',players : []}, {name : 'Team 1',players : []},{name : 'Team 1',players : []}


];

  const dummyInfo = {
    organizer: 'Organizer Name',
    venue: 'Venue Name',
    startDate: 'Tournament Start Date',
    endDate: 'Tournament End Date',
  };

  return (
    <div className="tournament-details-container">

{isModalOpen && (
        <AddMatchModal tournamentId={tournamentId} onClose={closeModal} onSubmit={handleMatchSubmit} />
      )}

{isTeamModalOpen && (
  <AddTeamModal 
    tournamentId={tournamentId} 
    onClose={() => handleCloseTeamModal()} 
  />
)}

{activeTab === "matches" ? (
  <span className='add-match'>
      <button className="add-match-button" onClick={() => openModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="add-match-icon" />
           <b>Add Match</b>
        </button>
      </span>
) : (
  console.log("Some other tab")
)}

{activeTab === "teams" ? (
  <span className='add-team'>
        <button className="add-team-button" onClick={() => setIsTeamModalOpen(true)}>
        <FontAwesomeIcon icon={faPlus} className="add-team-icon" />
        <b>Add Team</b>
      </button>
    </span>
) : (
  console.log("Some other tab")
)}


      
      <div className="banner-container">
        <div className="banner-left">
          <div className="banner-details">
            <h1>{tournamentData.name}</h1>
            <div id="genre">
              <span>{tournamentData.gameType}</span>
            </div>
            <p className="banner-descr">{tournamentData.description}</p>
          </div>
        </div>
        <div
          className="banner-right"
          style={{
            backgroundImage: `url(${tournamentData.thumbnailImage})`,
          }}
        >
          <div></div>
        </div>
      </div>
      <div className="tournament-details-content">

        <div className="tournament-details-tabs">
          {/* Render tabs */}
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={activeTab === tab.id ? 'active' : ''}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {/* Render active tab content */}
        {renderActiveTabContent()}
      </div>
    </div>
  );
}

export default MyTournamentDetailsPage;
